

















import { defineComponent } from "@vue/composition-api"

import type { PropType } from "@vue/composition-api"

import useJeopardAiFinaleCategory from "../use/useJeopardAiFinaleCategory"

import ChoiceBtn from "@/components/GroupTeams/Common/Games/GameCardParts/ChoiceBtn.vue"
import OptionsList from "@/components/GroupTeams/Common/Games/GameCardParts/OptionsList.vue"
import ResizableText from "@/components/GroupTeams/Common/Games/ResizableText.vue"
import PlayLayout from "@/components/GroupTeams/Common/GameModes/PlayLayout.vue"

export default defineComponent({
  name: "JeopardAiFinaleCategory",
  emits: ["category"],
  components: {
    ResizableText,
    PlayLayout,
    ChoiceBtn,
    OptionsList
  },
  props: {
    options: {
      type: Array as PropType<string[]>,
      required: true
    }
  },
  setup() {
    const {
      value,
      submit,
      isViewerHostLike,
      isViwerSelecting,
      loser,
      message
    } = useJeopardAiFinaleCategory()

    return {
      value,
      submit,
      isViewerHostLike,
      isViwerSelecting,
      loser,
      message
    }
  }
})
